import axios from "axios";
import store from "@/store";
import { Token } from "@/service/authentication";

export const Gender = {
  Male: 1,
  Female: 2,
};

export default {
  /**
   *
   * @param payload
   */
  accountPost(payload) {
    return axios.post(`/api/v1/account`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   * @param payload
   */
  accountIdPut(payload) {
    return axios.put(`/api/v1/account/${payload.id}`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   * @param payload
   */
  accountProfilePut(payload) {
    return axios.put(`/api/v1/account/profile`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   * @param payload
   */
  accountIdProfilePut(payload) {
    return axios.put(`/api/v1/account/${payload.id}/profile`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   */
  accountMeGet() {
    return axios.get(`/api/v1/account/me`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   * @param payload
   */
  accountIdGet(payload) {
    return axios.get(`/api/v1/account/${payload.id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   */
  accountGet() {
    return axios.get(`/api/v1/account`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   * @param payload
   */
  accountSeekGet(payload) {
    return axios.get(`/api/v1/account/seek`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   * @param payload
   */
  accountExistsGet(payload) {
    return axios.get(`/api/v1/account/exists`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${Token}`,
      },
    });
  },

  /**
   *
   * @param payload
   */
  accountInspectGet(payload) {
    return axios.get(`/api/v1/account/inspect`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   * @param payload
   */
  accountRegisterAttempPost(payload) {
    return axios.post(`/api/v1/account/register/attempt`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${Token}`,
      },
    });
  },

  /**
   *
   * @param payload
   */
  accountRegisterAcceptPost(payload) {
    return axios.post(`/api/v1/account/register/accept`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `otp ${payload.otp}`,
      },
    });
  },

  /**
   *
   * @param payload
   */
  accountPwdRecoverAttempPost(payload) {
    return axios.post(`/api/v1/account/pwd/recover/attempt`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${Token}`,
      },
    });
  },

  /**
   *
   * @param payload
   */
  accountPwdRecoverAcceptPost(payload) {
    return axios.post(`/api/v1/account/pwd/recover/accept`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `otp ${payload.otp}`,
      },
    });
  },

  /**
   *
   * @param payload
   */
  accountPwdChangePost(payload) {
    return axios.post(`/api/v1/account/pwd/change`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   * @param payload
   */
  accountIdPwdOverridePost(payload) {
    return axios.post(
      `/api/v1/account/${payload.id}/pwd/override`,
      payload.data,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${store.getters.token.accessToken}`,
        },
      }
    );
  },
};
