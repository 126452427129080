const state = {
  type: null,
  message: null,
};

const actions = {
  /**
   *
   * @param {*} param0
   * @param {*} message
   */
  success({ commit }, message) {
    commit("clear");
    commit("success", message);
  },

  /**
   *
   * @param {*} param0
   * @param {*} message
   */
  error({ commit }, message) {
    commit("clear");
    commit("error", message);
  },

  /**
   *
   * @param {*} param0
   */
  clear({ commit }) {
    commit("clear");
  },
};

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} message
   */
  success(state, message) {
    state.type = "success";
    state.message = message;
  },

  /**
   *
   * @param {*} state
   * @param {*} message
   */
  error(state, message) {
    state.type = "danger";
    state.message = message;
  },

  /**
   *
   * @param {*} state
   */
  clear(state) {
    state.type = null;
    state.message = null;
  },
};

export default {
  state,
  actions,
  mutations,
};
