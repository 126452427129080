<template>
  <div
    v-if="!lodash.isNil(notification.message)"
    :class="`alert alert-${notification.type} alert-dismissible`"
    role="alert"
  >
    {{ notification.message }}

    <button type="button" class="close" @click="$store.dispatch('clear')">
      <span aria-hidden="true">&times;</span>
    </button> 
  </div>
</template>

<script>
import lodash from "lodash";

export default {
  setup() {
    return {
      lodash: lodash,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  mounted() {
    this.$store.dispatch("clear");
  },
};
</script>