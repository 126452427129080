import axios from "axios";
import store from "@/store";

export const PaymentReasonMode = {
  License: 1,
  ShoppingCart: 2,
  CoinCart: 3,
  BalanceCart: 4,
  ActivationCart: 5,
  BucketCart: 6,
  ChallengeShoppingCart: 7,
  PremiumWorker: 8,
  LicenseRenovation: 9,
  TradingLicense: 10
};

export const PaymentStatus = {
  Pending: 1,
  Rejected: 2,
  Approved: 3,
  Canceled: 4,
};

export const PaymentMethod = {
  WalletBalance: 1,
  BankEvidenceOrCash: 2,
  PayPal: 3,
  Bitcoin: 4,
  Tron: 5,
  RRC: 6,
  Stripe: 7,
  RRCAuto: 8,
};

export default {
  /**
   *
   * @param {*} payload
   */
  paymentPreviewPost(payload) {
    return axios.post(`/api/v1/payment/preview`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   * @param {*} payload
   */
  paymentPost(payload) {
    return axios.post(`/api/v1/payment`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   * @param {*} payload
   */
  paymentGet(payload) {
    return axios.get(`/api/v1/payment`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   * @param {*} payload
   */
  paymentMethodAvailableGet(payload) {
    return axios.get(`/api/v1/payment/methods/available`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   * @param {*} payload
   */
  paymentManagerGet(payload) {
    return axios.get(`/api/v1/payment/manager`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   * @param {*} payload
   */
  paymentManagerRejectPut(payload) {
    return axios.put(
      `/api/v1/payment/${payload.id}/manager/reject`,
      payload.data,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${store.getters.token.accessToken}`,
        },
      }
    );
  },

  /**
   *
   * @param {*} payload
   */
  paymentManagerApprovePut(payload) {
    return axios.put(
      `/api/v1/payment/${payload.id}/manager/approve`,
      payload.data,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${store.getters.token.accessToken}`,
        },
      }
    );
  },

  /**
   *
   * @param {*} payload
   */
  paymentMethodCallbackAcceptPut(payload) {
    return axios.put(
      `/api/v1/payment/m/${payload.method}/callback/accept`,
      payload.data,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${store.getters.token.accessToken}`,
        },
      }
    );
  },

  /**
   *
   * @param {*} payload
   */
  paymentMethodCallbackCredentialGet(payload) {
    return axios.get(
      `/api/v1/payment/m/${payload.method}/callback/credential`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${store.getters.token.accessToken}`,
        },
      }
    );
  },

  /**
   *
   * @param {*} payload
   */
  paymentMethodCallbackRevokeDelete(payload) {
    return axios.delete(`/api/v1/payment/m/${payload.method}/callback/revoke`, {
      data: payload.data,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   *
   * @param {*} payload
   */
  paymentInvoiceGet(payload) {
    return axios.get(`/api/v1/payment/${payload.id}/invoice`, {
      data: payload.data,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },
};
