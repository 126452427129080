import Payment from "@/service/payment";

const state = {};

const getters = {};

const actions = {
  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  paymentPreviewPost(context, payload) {
    return new Promise((resolve, reject) => {
      Payment.paymentPreviewPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  paymentPost(context, payload) {
    return new Promise((resolve, reject) => {
      Payment.paymentPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  paymentGet(context, payload) {
    return new Promise((resolve, reject) => {
      Payment.paymentGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  paymentMethodAvailableGet(context, payload) {
    return new Promise((resolve, reject) => {
      Payment.paymentMethodAvailableGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  paymentManagerGet(context, payload) {
    return new Promise((resolve, reject) => {
      Payment.paymentManagerGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  paymentManagerRejectPut(context, payload) {
    return new Promise((resolve, reject) => {
      Payment.paymentManagerRejectPut(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  paymentManagerApprovePut(context, payload) {
    return new Promise((resolve, reject) => {
      Payment.paymentManagerApprovePut(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  paymentMethodCallbackAcceptPut(context, payload) {
    return new Promise((resolve, reject) => {
      Payment.paymentMethodCallbackAcceptPut(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  paymentMethodCallbackCredentialGet(context, payload) {
    return new Promise((resolve, reject) => {
      Payment.paymentMethodCallbackCredentialGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  paymentMethodCallbackRevokeDelete(context, payload) {
    return new Promise((resolve, reject) => {
      Payment.paymentMethodCallbackRevokeDelete(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  paymentInvoiceGet(context, payload) {
    return new Promise((resolve, reject) => {
      Payment.paymentInvoiceGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
