import axios from "axios";
import store from "@/store";

export const WalletStatus = {
  Inactive: 0,
  Active: 1,
};

export const WalletMode = {
  Account: 1,
  Product: 2,
};

export const WithdrawalStatus = {
  Pending: 1,
  Rejected: 2,
  Approved: 3,
  Canceled: 4,
  Locked: 5,
};

export const Unit = {
  USD: 1,
  RRC: 2,
  TRON: 3,
};

export const BankAccountType = {
  Savings: 1,
  Checking: 2,
};

export default {
  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletBalanceTransferPreviewPost(payload) {
    return axios.post("/api/v1/wallet/balance/transfer/preview", payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletWithdrawalPreviewPost(payload) {
    return axios.post(`/api/v1/wallet/withdrawal/preview`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletTransferAttemptPost(payload) {
    return axios.post("/api/v1/wallet/transfer/attempt", payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletWithdrawalAttemptPost(payload) {
    return axios.post(`/api/v1/wallet/withdrawal/attempt`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletBalanceTransferPost(payload) {
    return axios.post("/api/v1/wallet/balance/transfer", payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `otp ${payload.otp}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletWithdrawalPost(payload) {
    return axios.post(`/api/v1/wallet/withdrawal`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `otp ${payload.otp}`,
      },
    });
  },

  /**
   * 
   * @returns 
   */
  walletGet() {
    return axios.get("/api/v1/wallet", {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletTransactionGet(payload) {
    return axios.get(`/api/v1/wallet/transaction`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletWithdrawalGet(payload) {
    return axios.get(`/api/v1/wallet/withdrawal`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletWithdrawalCancelPut(payload) {
    return axios.put(
      `/api/v1/wallet/withdrawal/${payload.id}/cancel`,
      {},
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${store.getters.token.accessToken}`,
        },
      }
    );
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletWithdrawalRecipientsPut(payload) {
    return axios.put(`/api/v1/wallet/withdrawal/recipients`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletManagerListGet(payload) {
    return axios.get(`/api/v1/wallet/manager/list`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletManagerWithdrawalGet(payload) {
    return axios.get(`/api/v1/wallet/manager/withdrawal`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletManagerTransactionGet(payload) {
    return axios.get(`/api/v1/wallet/manager/transaction`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletManagerWithdrawalRejectPut(payload) {
    return axios.put(
      `/api/v1/wallet/manager/withdrawal/${payload.id}/reject`,
      payload.data,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${store.getters.token.accessToken}`,
        },
      }
    );
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletManagerWithdrawalApprovePut(payload) {
    return axios.put(
      `/api/v1/wallet/manager/withdrawal/${payload.id}/approve`,
      payload.data,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${store.getters.token.accessToken}`,
        },
      }
    );
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletManagerWithdrawalTronApprovePut(payload) {
    return axios.put(
      `/api/v1/wallet/manager/withdrawal/${payload.id}/tron/approve`,
      payload.data,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${store.getters.token.accessToken}`,
        },
      }
    );
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletManagerWithdrawalActionLockPut(payload) {
    return axios.put(
      `/api/v1/wallet/manager/withdrawal/${payload.id}/action/lock`,
      payload.data,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${store.getters.token.accessToken}`,
        },
      }
    );
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletManagerWithdrawalActionUnlockPut(payload) {
    return axios.put(
      `/api/v1/wallet/manager/withdrawal/${payload.id}/action/unlock`,
      payload.data,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${store.getters.token.accessToken}`,
        },
      }
    );
  },
};
