<template>
  <footer class="footer-section">
    <div class="bg-shape--top">
      <img src="@/assets/images/elements/round-shape-2.png" alt="image" />
    </div>
    <div class="container pt-120">
      <div class="row pb-5 align-items-center">
        <div class="col-lg-4"></div>
        <div class="col-lg-8">
          <ul class="short-links justify-content-lg-end justify-content-center">
            <li>
              <a href="#0">
                {{ $t("text.terms-conditions") }}
              </a>
            </li>
            <li>
              <a href="#0">
                {{ $t("text.privacy-policies") }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div class="row py-5 align-items-center">
        <div class="col-lg-6">
          <p class="copy-right-text text-lg-left text-center mb-lg-0 mb-3">
            Copyright © {{ new Date().getFullYear() }}.
            {{ $t("text.all-rights-reserved-by") }}
            <a href="https://office.rrenova.com/" target="_blank">RRENOVA</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>