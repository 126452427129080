<template>
  <!-- <ScrollToTop /> -->

  <div class="page-wrapper">
    <!-- header-section start  -->
    <Header />
    <!-- header-section end  -->

    <router-view />

    <!-- footer section start  -->
    <Footer />
    <!-- footer section end -->
  </div>

  <LoginModal />
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import LoginModal from "@/components/modal/login_modal.vue";

export default {
  components: {
    Header,
    Footer,
    LoginModal,
  },
  mounted() {
    if (this.$store.getters.account != null) {
      this.$store.dispatch("accountMeGet").then((response) => {
        if (response.data.data.ucode == "novasys") {
          this.$store.dispatch("resetAuthenticationState");
        } else {
          this.$store.dispatch("vchallengeShopCartGet");
          this.$store.dispatch("walletGet");
          this.$store.dispatch("walletRRCGet");
        }
      });
    }

    this.$store.dispatch("geoCountryGet");
  },
};
</script>
