import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    console.log(config);
    return config;
  },
  function(error) {
    console.error(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    console.log(response);
    return response;
  },
  function(error) {
    console.error(error.response);
    return Promise.reject(error);
  }
);
