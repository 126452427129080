import axios from "axios";
import store from "@/store";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import appConfig from "@/appConfig";

axios.defaults.baseURL = appConfig.getVueAppBaseUrl();

const refreshAuthLogic = (failedRequest) =>
    store
        .dispatch("authTokenRefreshTokenPost")
        .then(() => {
            failedRequest.response.config.headers[
                "Authorization"
                ] = `Bearer ${store.getters.token.accessToken}`;
            return Promise.resolve();
        })
        .catch(() => {
            store.dispatch("resetAuthenticationState");
            store.dispatch("resetAccountState");
        });

createAuthRefreshInterceptor(axios, refreshAuthLogic);

export default axios;
