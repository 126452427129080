// import store from "../store";

export default [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home.vue"),
  },

  {
    path: "/products",
    name: "products",
    component: () => import("../views/products.vue"),
  },

  {
    path: "/product/:id([0-9a-fA-F]{24})",
    name: "product",
    component: () => import("../views/product_details.vue"),
  },

  {
    path: "/contests",
    name: "contests",
    component: () => import("../views/contests.vue"),
  },

  {
    path: "/ranking",
    name: "ranking",
    component: () => import("../views/ranking.vue"),
  },

  {
    path: "/profile",
    name: "profile",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/profile.vue"),
  },

  {
    path: "/shopping-cart",
    name: "shopping-cart",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/shopping_cart.vue"),
  },

  {
    path: "/checkout/:id/:mode",
    name: "checkout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (routeFrom.name == "shopping-cart") {
          next();
        } else {
          next({ name: "shopping-cart" });
        }
      },
    },
    component: () => import("../views/checkout.vue"),
  },

  {
    path: "/checkout/confirmed",
    name: "checkout-confirmed",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (routeFrom.name == "checkout") {
          next();
        } else {
          next({ name: "shopping-cart" });
        }
      },
    },
    component: () => import("../views/checkout_confirmed.vue"),
  },

  {
    path: "/personal-information",
    name: "personal-information",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/personal-information.vue"),
  },
];
