import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./i18n";
import "./plugins/axiosInterceptors";
import "./plugins/axiosLogInterceptor";
import mixin from "./plugins/mixin";
import Pagination from 'v-pagination-3';

createApp(App).component('pagination', Pagination).mixin(mixin).use(store).use(i18n).use(router).mount('#app');
