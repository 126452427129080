<script>
import lodash from "lodash";

export default {
  name: "ShoppingCartSummary",
  setup() {
    return {
      lodash: lodash,
    };
  },
};
</script>

<template>
  <div
    class="product__cart"
    v-if="!lodash.isNil($store.getters.vChallengeCart)"
  >
    <span class="total__amount">
      {{ `$${lodash.floor($store.getters.vChallengeCart.cartTotal, 2)}` }}
    </span>
    <router-link :to="{ name: 'shopping-cart' }" tag="a" class="amount__btn">
      <i class="las la-shopping-cart"></i>
      <span class="cart__num">
        {{ $store.getters.vChallengeCart.total }}
      </span>
    </router-link>
  </div>
</template>