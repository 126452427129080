import Authentication from "@/service/authentication";
import { GrantType } from "@/service/authentication";

const state = {
  token: sessionStorage.getItem("token"),
};

const getters = {
  token: (state) => (state.token != null ? JSON.parse(state.token) : null),
  isAuthenticated: (state) => state.token != null,
};

const actions = {
  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  authTokenPasswordPost(context, payload) {
    return new Promise((resolve, reject) => {
      Authentication.authTokenPasswordPost({
        username: payload.username,
        password: payload.password,
        grantType: GrantType.PASSWORD,
      })
        .then((response) => {
          context.commit("saveToken", {
            accessToken: response.data.data.accessToken,
            refreshToken: response.data.data.refreshToken,
            scope: response.data.data.scope,
          });
          resolve(response);
        })
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} param0
   */
  authTokenRefreshTokenPost({ commit }) {
    return new Promise((resolve, reject) => {
      Authentication.authTokenRefreshTokenPost()
        .then((response) => {
          commit("saveToken", {
            accessToken: response.data.data.accessToken,
            refreshToken: response.data.data.refreshToken,
            scope: response.data.data.scope,
          });
          resolve(response);
        })
        .catch(reject);
    });
  },

  /**
   *
   */
  authTokenDelete(context) {
    return new Promise((resolve, reject) => {
      Authentication.authTokenDelete()
        .then((response) => {
          context.commit("deleteToken");
          resolve(response);
        })
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  authValidateGet(context, payload) {
    return new Promise((resolve, reject) => {
      Authentication.authValidateGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   */
  resetAuthenticationState(context) {
    context.commit("deleteToken");
  },
};

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} payload
   */
  saveToken(state, payload) {
    state.token = JSON.stringify(payload);
    sessionStorage.setItem("token", JSON.stringify(payload));
  },

  /**
   * 
   * @param {*} state 
   */
  deleteToken(state) {
    state.token = null;
    sessionStorage.clear();
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
