import axios from "axios";
import store from "@/store";
import appConfig from "@/appConfig";

export const GrantType = {
    password: "password",
    clientCredentials: "client_credentials",
    refreshToken: "refresh_token",
};

export const Token = appConfig.getBasicToken();
export default {
    /**
     *
     * @param payload
     */
    authTokenPasswordPost(payload) {
        return axios.post(
            "/api/v1/auth/token",
            {
                username: payload.username,
                password: payload.password,
                grantType: GrantType.password,
            },
            {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Basic ${Token}`,
                },
            }
        );
    },

    /**
     *
     */
    authTokenClientCredentialsPost() {
        return axios.post(
            "/api/v1/auth/token",
            {
                grantType: GrantType.clientCredentials,
            },
            {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Basic ${Token}`,
                },
            }
        );
    },

    /**
     *
     */
    authTokenRefreshTokenPost() {
        return axios.post(
            "/api/v1/auth/token",
            {
                grantType: GrantType.refreshToken,
                refreshToken: store.getters.token.refreshToken,
            },
            {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Basic ${Token}`,
                },
            }
        );
    },

    /**
     *
     */
    authTokenDelete() {
        return axios.delete("/api/v1/auth/token", {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${store.getters.token.accessToken}`,
            },
        });
    },

    /**
     *
     * @param payload
     */
    authValidateGet(payload) {
        return axios.get("/api/v1/auth/validate", {
            headers: {
                "Content-type": "application/json",
                Authorization: `otp ${payload.otp}`,
            },
        });
    },
};
