const config = require("./data/config/appConfig.json")

export default {
    getBasicToken() {
        return config.basicToken;
    },
    getVueAppBaseUrl() {
        return config[window.location.hostname].vueAppBaseUrl;
    },
    getVueAppBaseUrlMqtt() {
        return config[window.location.hostname].vueAppBaseUrlMqtt;
    },
    getVueAppI18nLocale() {
        return config.vueAppI18nLocale;
    },
    getVueAppI18nFallbackLocale() {
        return config.vueAppI18nFallbackLocale;
    }
}
