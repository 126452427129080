import store from "@/store";
import moment from "moment";
import axios from "axios";
import lodash from "lodash";
import { encode } from "url-encode-decode";
import { PaymentMethod, PaymentReasonMode } from "@/service/payment";
import { Token } from "@/service/authentication"
import appConfig from "@/appConfig";

export default {
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/flags/flag_us.png"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/flags/flag_es.png"),
          language: "es",
          title: "Español",
        },
        {
          flag: require("@/assets/flags/flag_br.png"),
          language: "pt",
          title: "Português",
        },
      ],

      baseURL: `${axios.defaults.baseURL}`,

      mqttClient: null,
    };
  },
  methods: {
    initials(text) {
      const words = text.trim().split(/[\s,]+/);
      let initials = "";
      for (let index = 0; index < words.length; index++) {
        if (index >= 2) break;
        initials += words[index][0];
      }
      return initials;
    },

    getImage(filename) {
      return `${this.baseURL}/api/v2/image/do/${filename}`
    },

    getQrCode(payload) {
      return `${this.baseURL}/api/v1/image/gen/barcode?payload=${payload}&scale=3`
    },

    getIniticon(account) {
      if (!lodash.isNil(account.profile.firstname)) {
        return `${this.baseURL}/api/v1/image/gen/initicon?name=${encode(`${account.profile.firstname} ${account.profile.lastname}`)}&color=5A4BCC&size=256`
      } else {
        return `${this.baseURL}/api/v1/image/gen/initicon?name=${account.ucode}&color=5A4BCC&size=256`
      }
    },

    getAvatar(account) {
      if (!lodash.isNil(account.profile.picture)) {
        return this.getImage(account.profile.picture)
      } else {
        return this.getIniticon(account)
      }
    },

    async getCountry(id) {
      const response = await this.$store.dispatch("geoCountryIdGet", {
        id: id,
      });

      if (!lodash.isNil(response) && !lodash.isNil(response.data.data)) {
        return response.data.data;
      }

      return null;
    },

    toHTML(text) {
      return text.split("\n").join("<br/>");
    },

    hasLicense() {
      if (store.getters.isAuthenticated && store.getters.account != null) {
        return store.getters.account.license != null;
      } else {
        return false;
      }
    },

    hasActiveLicense() {
      if (this.hasLicense()) {
        return moment(Date.now()).isBefore(
          store.getters.account.license.activeExpirationDate
        );
      } else {
        return false;
      }
    },

    isActiveLicense(license) {
      if (!lodash.isNil(license)) {
        return moment(Date.now()).isBefore(license.activeExpirationDate);
      } else {
        return false;
      }
    },

    downloadFile(filename) {
      this.$store
        .dispatch("fileGet", {
          filename: filename,
        })
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = url;
          link.download = filename;
          link.click();
        });
    },

    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },

    formatBigCount(count) {
      if (count >= 1000000) {
        return `${lodash.floor(count / 1000000, 1)}M`;
      } else if (count >= 1000) {
        return `${lodash.floor(count / 1000, 1)}K`;
      } else {
        return count;
      }
    },

    formatToLowerCase(value) {
      return value.toLowerCase();
    },

    getErrorMessage(error) {
      if (!lodash.isNil(error.response)) {
        return error.response.data.message;
      } else if (!lodash.isNil(error.message)) {
        return error.message;
      } else {
        return error;
      }
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.$store.dispatch("success", this.$t("text.copied-to-clipboard"));
    },

    availableMethods(mode) {
      switch (mode) {
        case PaymentReasonMode.License:
        case PaymentReasonMode.ShoppingCart:
        case PaymentReasonMode.BucketCart:
        case PaymentReasonMode.ChallengeShoppingCart:
        case PaymentReasonMode.LicenseRenovation:
          return [
            PaymentMethod.RRC,
          ];
        case PaymentReasonMode.BalanceCart:
          return [
            PaymentMethod.BankEvidenceOrCash,
            PaymentMethod.Bitcoin,
            PaymentMethod.Tron,
            PaymentMethod.Stripe,
          ];
        case PaymentReasonMode.PremiumWorker:
          return [
            PaymentMethod.WalletBalance,
            PaymentMethod.BankEvidenceOrCash,
            PaymentMethod.Bitcoin,
            PaymentMethod.Tron,
            PaymentMethod.Stripe,
          ];
        case PaymentReasonMode.TradingLicense:
          return [
            PaymentMethod.BankEvidenceOrCash,
            PaymentMethod.Bitcoin,
            PaymentMethod.Stripe,
          ];
        default:
          return [];
      }
    },
    connectMqttClient() {
      if (!lodash.isNil(store.getters.account)) {
        if (this.mqttClient) {
          if (this.mqttClient.connected) {
            return;
          }
          this.mqttClient.end();
        }

        const mqtt = require('mqtt')

        const url = appConfig.getVueAppBaseUrlMqtt();
        const options = {
          username: `Basic ${Token}`,
          clientId: `${Date.now().toString()}-${store.getters.account._id}`,
          clear: true,
        };

        this.mqttClient = mqtt.connect(url, options);

        this.mqttClient?.on("connect", function () {
          console.log("CONNECT");
        });
        this.mqttClient?.on("message", (topic, payload) => {
          console.log(`Topic: ${topic}`);
          console.log(`Payload: ${payload}`);
        });
      }
    }
  },
}
