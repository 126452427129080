import Wallet from "@/service/wallet";

const state = {
  wallet: null,
};

const getters = {
  wallet: (state) => state.wallet,
};

const actions = {
  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletBalanceTransferPreviewPost(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletBalanceTransferPreviewPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletWithdrawalPreviewPost(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletWithdrawalPreviewPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletTransferAttemptPost(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletTransferAttemptPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletWithdrawalAttemptPost(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletWithdrawalAttemptPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletBalanceTransferPost(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletBalanceTransferPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletWithdrawalPost(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletWithdrawalPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @returns 
   */
  walletGet(context) {
    return new Promise((resolve, reject) => {
      Wallet.walletGet()
        .then((response) => {
          context.commit("saveWallet", response.data.data);
          resolve(response);
        })
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletTransactionGet(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletTransactionGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletWithdrawalGet(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletWithdrawalGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletWithdrawalCancelPut(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletWithdrawalCancelPut(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletWithdrawalRecipientsPut(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletWithdrawalRecipientsPut(payload)
        .then((response) => {
          context.commit("saveWallet", response.data.data);
          resolve(response);
        })
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletManagerListGet(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletManagerListGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletManagerWithdrawalGet(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletManagerWithdrawalGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletManagerTransactionGet(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletManagerTransactionGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletManagerWithdrawalRejectPut(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletManagerWithdrawalRejectPut(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletManagerWithdrawalApprovePut(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletManagerWithdrawalApprovePut(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletManagerWithdrawalTronApprovePut(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletManagerWithdrawalTronApprovePut(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletManagerWithdrawalActionLockPut(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletManagerWithdrawalActionLockPut(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletManagerWithdrawalActionUnlockPut(context, payload) {
    return new Promise((resolve, reject) => {
      Wallet.walletManagerWithdrawalActionUnlockPut(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   */
  resetWalletState(context) {
    context.commit("deleteWallet");
  },
};

const mutations = {
  /**
   * 
   * @param {*} state 
   * @param {*} payload 
   */
  saveWallet(state, payload) {
    state.wallet = payload;
  },

  /**
   * 
   * @param {*} state 
   */
  deleteWallet(state) {
    state.wallet = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
