<template>
  <div class="language">
    <i class="las la-globe-europe"></i>
    <select @change="setLanguage" class="nice-select">
      <option
        v-for="language in languages"
        :key="`language-${language.language}`"
        :value="language.language"
      >
        {{ language.title }}
      </option>
    </select>
  </div>
</template>

<script>
import i18n from "../i18n";
import lodash from "lodash";
import moment from "moment";

export default {
  name: "Language",
  data() {
    return {
      language: null,
      locale: i18n.getLocale(),
    };
  },
  methods: {
    setLanguage(event) {
      this.language = lodash.find(this.languages, {
        language: event.target.value,
      });
      this.setLocale(this.language.language);
    },
    setLocale(locale) {
      i18n.setLocale(locale);
      moment.locale(locale);
    },
  },
  mounted() {
    const navigatorLanguage = lodash.find(this.languages, {
      language: navigator.language.split("-")[0],
    });
    if (!lodash.isNil(navigatorLanguage)) {
      this.language = navigatorLanguage;
      i18n.setLocale(this.language.language);
      moment.locale(this.language.language);
    } else {
      this.language = lodash.find(this.languages, {
        language: i18n.getLocale(),
      });
    }
  },
};
</script>