<template>
  <div
    class="modal fade"
    id="login-modal"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="loginModal"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-body">
          <div class="account-form-area">
            <button
              type="button"
              class="close-btn"
              data-dismiss="modal"
              aria-label="Close"
              :disabled="executing"
              @click="$store.dispatch('clear')"
            >
              <i class="las la-times"></i>
            </button>
            <h3 class="title">
              {{ $t("text.sign-in") }}
            </h3>
            <Notification />
            <div class="account-form-wrapper">
              <template v-if="!$store.getters.isAuthenticated">
                <form ref="form" @submit.prevent="signIn">
                  <div class="form-group">
                    <label>
                      {{ `${$t("label.email")} ` }}
                      <sup>*</sup>
                    </label>

                    <input
                      class="form-control"
                      :placeholder="$t('placeholder.email')"
                      type="email"
                      v-model="v$.form.email.$model"
                      style="color: #ffffff !important"
                    />
                    <div
                      class="pre-icon os-icon os-icon-user-male-circle"
                    ></div>

                    <div
                      class="input-errors"
                      v-if="!v$.form.email.required.$response"
                    >
                      {{ $t("error.field-required") }}
                    </div>

                    <div
                      class="input-errors"
                      v-if="
                        form.email != null && !v$.form.email.email.$response
                      "
                    >
                      {{ $t("error.invalid-email") }}
                    </div>
                  </div>

                  <div class="form-group">
                    <label>
                      {{ `${$t("label.password")} ` }}
                      <sup>*</sup>
                    </label>
                    <input
                      class="form-control"
                      :placeholder="$t('placeholder.password')"
                      type="password"
                      v-model="v$.form.password.$model"
                      style="color: #ffffff !important"
                    />
                    <div class="pre-icon os-icon os-icon-fingerprint"></div>

                    <div
                      class="input-errors"
                      v-if="!v$.form.password.required.$response"
                    >
                      {{ $t("error.field-required") }}
                    </div>
                  </div>

                  <div class="d-flex flex-wrap justify-content-end mt-2">
                    <a
                      href="https://office.rrenova.com/forgot-password"
                      target="_blank"
                      class="link"
                    >
                      {{ $t("button.forgot-your-password") }}
                    </a>
                  </div>
                  <div class="form-group text-center mt-5">
                    <button
                      :disabled="v$.form.$invalid || executing"
                      class="cmn-btn"
                      type="submit"
                    >
                      <span
                        v-if="executing"
                        class="spinner-border spinner-border-sm mb-1 mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>

                      {{ $t("button.sign-in") }}
                    </button>
                  </div>
                </form>

                <p class="text-center mt-4">
                  {{ $t("text.do-not-have-an-account") }}
                  <a href="https://office.rrenova.com/sign-up" target="_blank">
                    {{ $t("button.sign-up-now") }}
                  </a>
                </p>
              </template>
              <template v-else>
                <div class="text-center">
                  <p class="wow">
                    {{ $t("text.authenticated-description") }}
                  </p>

                  <button
                    class="cmn-btn mt-4"
                    type="button"
                    data-dismiss="modal"
                  >
                    {{ $t("button.accept") }}
                  </button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import Notification from "@/components/notification.vue";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      executing: false,
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      },
    };
  },
  methods: {
    signIn() {
      this.$store.dispatch("clear");
      try {
        this.executing = true;
        this.$store
          .dispatch("authTokenPasswordPost", {
            username: this.form.email,
            password: this.form.password,
          })
          .then(() => {
            this.$store
              .dispatch("accountMeGet")
              .then((response) => {
                if (response.data.data.ucode == "novasys") {
                  this.$store.dispatch("resetAuthenticationState");
                  this.$store.dispatch(
                    "error",
                    this.$t("error.invalid-credentials")
                  );
                  this.executing = false;
                } else {
                  this.$store.dispatch("vchallengeShopCartGet");
                  this.$store.dispatch("walletGet");
                  this.$store.dispatch("walletRRCGet");

                  this.executing = false;
                }
              })
              .catch((error) => {
                this.$store.dispatch("error", this.getErrorMessage(error));
                this.executing = false;
              });
          })
          .catch((error) => {
            if (!lodash.isNil(error.response)) {
              switch (error.response.status) {
                case 401:
                  this.$store.dispatch(
                    "error",
                    this.$t("error.invalid-credentials")
                  );
                  break;
                default:
                  this.$store.dispatch("error", this.getErrorMessage(error));
                  break;
              }
            } else {
              this.$store.dispatch("error", this.getErrorMessage(error));
            }
            this.executing = false;
          });
      } catch (error) {
        this.$store.dispatch("error", this.getErrorMessage(error));
        this.executing = false;
      }
    },
  },
  components: { Notification },
};
</script>