import axios from "axios";
import { Token } from "@/service/authentication";

export default {
  /**
   * 
   * @returns 
   */
  geoCountryGet() {
    return axios.get("/api/v1/geo/country", {
      headers: {
        Authorization: `Basic ${Token}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  geoCountryIdGet(payload) {
    return axios.get(`/api/v1/geo/country/${payload.id}`, {
      headers: {
        Authorization: `Basic ${Token}`,
      },
    });
  },
};
