import { createStore } from 'vuex'

import account from "./modules/account";
import authentication from "./modules/authentication";
import geo from "./modules/geo"
import notification from './modules/notification';
import payment from './modules/payment';
import vchallenge from './modules/vchallenge';
import walletRRC from './modules/wallet-rrc';
import wallet from './modules/wallet';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    account,
    authentication,
    geo,
    notification,
    payment,
    vchallenge,
    walletRRC,
    wallet,
  },
  strict: process.env.NODE_ENV !== "production",
})
