import Geo from "@/service/geo";

const state = {};

const getters = {};

const actions = {
  /**
   * 
   * @returns 
   */
  geoCountryGet() {
    return new Promise((resolve, reject) => {
      Geo.geoCountryGet()
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  geoCountryIdGet(context, payload) {
    return new Promise((resolve, reject) => {
      Geo.geoCountryIdGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
