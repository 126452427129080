import axios from "axios";
import store from "@/store";
import { Token } from "@/service/authentication";

export const ChallengeLevel = {
  LEVEL1: 50,
  LEVEL2: 1000,
  LEVEL3: 5000,
  LEVEL4: 10000,
  LEVEL5: 25000
}

export const ChallengeStatus = {
  INPROGRESS: 0,
  FINISHED: 1,
}

export const ChallengeWinnerStatus = {
  REJECTED: 0,
  FINAL: 1,
  LEVEL1: 2,
  LEVEL2: 3,
  LEVEL3: 4
}

export default {
  /**
   * 
   * @returns 
   */
  vchallengeShopCartGet() {
    return axios.get(`/api/v2/vchallenge/shop/cart`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  vchallengeShopCartSuggestionsGet(payload) {
    return axios.get(`/api/v2/vchallenge/shop/cart/suggestions`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  vchallengeShopCartProductItemPost(payload) {
    return axios.post(`/api/v2/vchallenge/shop/cart/product/item`, payload.data, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  vchallengeShopCartProductItemPut(payload) {
    return axios.put(`/api/v2/vchallenge/shop/cart/product/item`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  vchallengeShopCartShippingCountryPut(payload) {
    return axios.put(`/api/v2/vchallenge/shop/cart/shipping/country`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  vchallengeShopCartProductItemDelete(payload) {
    return axios.delete(`/api/v2/vchallenge/shop/cart/product/item`, {
      data: payload.data,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @returns 
   */
  vchallengeShopCartProductItemAllDelete() {
    return axios.delete(`/api/v2/vchallenge/shop/cart/product/item/all`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  vchallengeShopProductSeekGet(payload) {
    return axios.get(`/api/v2/vchallenge/shop/rrenova/product/seek`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${Token}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  vchallengeShopProductDetailGet(payload) {
    return axios.get(`/api/v2/vchallenge/shop/rrenova/product/${payload.id}/detail`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${Token}`,
      },
    });
  },

  /**
   *
   * @param {*} payload
   * @returns
   */
  vchallengeShopProductStockOptionCheckPost(payload) {
    return axios.post(`/api/v2/vchallenge/shop/rrenova/product/${payload.id}/stock/option/check`, payload.data, {
      headers: {
        Authorization: `Basic ${Token}`,
      }
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  vchallengeGet(payload) {
    return axios.get(`/api/v2/vchallenge`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${Token}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  vchallengeAdminGet(payload) {
    return axios.get(`/api/v2/vchallenge/admin`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  vchallengeMeGet(payload) {
    return axios.get(`/api/v2/vchallenge/me`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  vchallengeDeliverPost(payload) {
    return axios.post(`/api/v2/vchallenge/${payload.id}/deliver`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${Token}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  vchallengeRankingLevelGet(payload) {
    return axios.get(`/api/v2/vchallenge/ranking/${payload.level}`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${Token}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  vchallengeRankingGet(payload) {
    return axios.get(`/api/v2/vchallenge/${payload.id}/ranking`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${Token}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
   vchallengeAvailableGet(payload) {
    return axios.get(`/api/v2/vchallenge/available`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },
}