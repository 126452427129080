import WalletRRC from "@/service/wallet-rrc";

const state = {
  walletRRC: null,
};

const getters = {
  walletRRC: (state) => state.walletRRC,
};

const actions = {
  /**
   * 
   * @param {*} context 
   * @returns 
   */
  walletRRCGet(context) {
    return new Promise((resolve, reject) => {
      WalletRRC.walletRRCGet()
        .then((response) => {
          context.commit("saveWalletRRC", response.data.data);
          resolve(response);
        })
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletRRCSeekGet(context, payload) {
    return new Promise((resolve, reject) => {
      WalletRRC.walletRRCSeekGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletCoinMarketGet(context, payload) {
    return new Promise((resolve, reject) => {
      WalletRRC.walletCoinMarketGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletRRCTransactionGet(context, payload) {
    return new Promise((resolve, reject) => {
      WalletRRC.walletRRCTransactionGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletRRCTransactionHashGet(context, payload) {
    return new Promise((resolve, reject) => {
      WalletRRC.walletRRCTransactionHashGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletRRCTransferPost(context, payload) {
    return new Promise((resolve, reject) => {
      WalletRRC.walletRRCTransferPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletRRCTransferPreviewPost(context, payload) {
    return new Promise((resolve, reject) => {
      WalletRRC.walletRRCTransferPreviewPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletRRCTransferAttemptPost(context, payload) {
    return new Promise((resolve, reject) => {
      WalletRRC.walletRRCTransferAttemptPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletRRCExchangeOrderSellMineGet(context, payload) {
    return new Promise((resolve, reject) => {
      WalletRRC.walletRRCExchangeOrderSellMineGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletRRCExchangeOrderSellGet(context, payload) {
    return new Promise((resolve, reject) => {
      WalletRRC.walletRRCExchangeOrderSellGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletRRCExchangeOrderSellPost(context, payload) {
    return new Promise((resolve, reject) => {
      WalletRRC.walletRRCExchangeOrderSellPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletRRCExchangeOrderSellCancelPut(context, payload) {
    return new Promise((resolve, reject) => {
      WalletRRC.walletRRCExchangeOrderSellCancelPut(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletRRCExchangeOrderBuyMineGet(context, payload) {
    return new Promise((resolve, reject) => {
      WalletRRC.walletRRCExchangeOrderBuyMineGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletRRCExchangeOrderBuyGet(context, payload) {
    return new Promise((resolve, reject) => {
      WalletRRC.walletRRCExchangeOrderBuyGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  walletRRCExchangeOrderBuyPost(context, payload) {
    return new Promise((resolve, reject) => {
      WalletRRC.walletRRCExchangeOrderBuyPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   */
  resetWalletRRCState(context) {
    context.commit("deleteWalletRRC");
  },
};

const mutations = {
  /**
   * 
   * @param {*} state 
   * @param {*} payload 
   */
  saveWalletRRC(state, payload) {
    state.walletRRC = payload;
  },

  /**
   * 
   * @param {*} state 
   */
  deleteWalletRRC(state) {
    state.walletRRC = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
