<template>
  <div>
    <header class="header">
      <div class="header__top">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <div class="left d-flex align-items-center">
                <router-link :to="{ name: 'home' }" tag="a">
                  vChallenge
                </router-link>

                <Language />
              </div>
            </div>

            <div class="col-sm-6">
              <div class="right">
                <template
                  v-if="
                    $store.getters.isAuthenticated &&
                    $store.getters.account != null
                  "
                >
                  <ShoppingCartSummary />

                  <router-link :to="{ name: 'profile' }" tag="a" class="ml-4">
                    <div class="avatar__thumb">
                      <img
                        :src="getAvatar($store.getters.account)"
                        alt="avatar"
                      />
                    </div>
                  </router-link>
                </template>
                <template v-else>
                  <a
                    href="#0"
                    class="user__btn"
                    data-toggle="modal"
                    data-target="#login-modal"
                  >
                    <i class="las la-user"></i>
                  </a>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- header__top end -->
      <div class="header__bottom">
        <div class="container">
          <nav class="navbar navbar-expand-xl p-0 align-items-center">
            <router-link
              :to="{ name: 'home' }"
              tag="a"
              class="site-logo site-title"
            >
              <img
                src="@/assets/logo.png"
                alt="vChallenge"
                width="147"
                height="77"
              />
              <span class="logo-icon">
                <i class="flaticon-fire"></i>
              </span>
            </router-link>

            <button
              class="navbar-toggler ml-auto"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="menu-toggle"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav main-menu ml-auto">
                <li>
                  <router-link :to="{ name: 'home' }">
                    {{ $t("text.home") }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'products' }">
                    {{ $t("text.products") }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'contests' }">
                    {{ $t("text.contests") }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'ranking' }">
                    {{ $t("text.ranking") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <!-- header__bottom end -->
    </header>
  </div>
</template>

<script>
import Language from "@/components/language.vue";
import ShoppingCartSummary from "@/components/shopping-cart-summary.vue";

export default {
  name: "Header",
  components: {
    Language,
    ShoppingCartSummary,
  },
};
</script>

<style>
.header .main-menu li a.router-link-active.router-link-exact-active {
  color: #ff0000 !important;
}

.avatar__thumb {
  width: 40px;
  height: 40px;
  display: inline-block;
}

.avatar__thumb img {
  display: inline-block;
  width: 40px;
  height: 40px;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
</style>