import vChallenge from "@/service/vchallenge";

const state = {
  vChallengeCart: null,
};

const getters = {
  vChallengeCart: (state) => state.vChallengeCart,
};

const actions = {
  /**
   * 
   * @param {*} param0 
   * @returns 
   */
  vchallengeShopCartGet({ commit }) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeShopCartGet()
        .then((response) => {
          commit("saveCart", response.data.data);
          resolve(response);
        })
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  vchallengeShopCartSuggestionsGet(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeShopCartSuggestionsGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  vchallengeShopCartProductItemPost(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeShopCartProductItemPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  vchallengeShopCartProductItemPut(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeShopCartProductItemPut(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  vchallengeShopCartShippingCountryPut(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeShopCartShippingCountryPut(payload)
        .then((response) => {
          context.commit("saveCart", response.data.data);
          resolve(response);
        })
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  vchallengeShopCartProductItemDelete(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeShopCartProductItemDelete(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  vchallengeShopCartProductItemAllDelete(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeShopCartProductItemAllDelete(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  vchallengeShopProductSeekGet(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeShopProductSeekGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  vchallengeShopProductDetailGet(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeShopProductDetailGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  vchallengeShopProductStockOptionCheckPost(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeShopProductStockOptionCheckPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  vchallengeGet(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  vchallengeAdminGet(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeAdminGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  vchallengeMeGet(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeMeGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  vchallengeDeliverPost(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeDeliverPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  vchallengeRankingLevelGet(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeRankingLevelGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  vchallengeRankingGet(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeRankingGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
   vchallengeAvailableGet(context, payload) {
    return new Promise((resolve, reject) => {
      vChallenge.vchallengeAvailableGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   */
  resetCartState(context) {
    context.commit("deleteCart");
  },
};

const mutations = {
  /**
   * 
   * @param {*} state 
   * @param {*} payload 
   */
  saveCart(state, payload) {
    state.vChallengeCart = payload;
  },

  /**
   * 
   * @param {*} state 
   */
  deleteCart(state) {
    state.vChallengeCart = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
