import axios from "axios";
import store from "@/store";

export const WalletRRCTypeOrder = {
  Coin: 1,
  USD: 2,
};

export const WithdrawalRRCAccountMethod = {
  BankAccount: 1,
  Btc: 2,
  Paypal: 3,
  Tron: 4,
  USDT_TRC20: 5,
  USDT_ERC20: 6,
}

export const WalletRRCExchangeStatus = {
  Open: 1,
  Completed: 2,
  Canceled: 3,
}

export default {
  /**
   * 
   * @returns 
   */
  walletRRCGet() {
    return axios.get(`/api/v2/wallet/rrc`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletRRCSeekGet(payload) {
    return axios.get(`/api/v2/wallet/rrc/seek`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletCoinMarketGet(payload) {
    return axios.get(`/api/v2/wallet/coin/market`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletRRCTransactionGet(payload) {
    return axios.get(`/api/v2/wallet/rrc/transaction`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletRRCTransactionHashGet(payload) {
    return axios.get(`/api/v2/wallet/rrc/transaction/${payload.hash}`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletRRCTransferPost(payload) {
    return axios.post(`/api/v2/wallet/rrc/transfer`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `otp ${payload.otp}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletRRCTransferPreviewPost(payload) {
    return axios.post(`/api/v2/wallet/rrc/transfer/preview`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletRRCTransferAttemptPost(payload) {
    return axios.post(`/api/v2/wallet/rrc/transfer/attempt`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletRRCExchangeOrderSellMineGet(payload) {
    return axios.get(`/api/v2/wallet/rrc/exchange/order/sell/mine`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletRRCExchangeOrderSellGet(payload) {
    return axios.get(`/api/v2/wallet/rrc/exchange/order/sell`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletRRCExchangeOrderSellPost(payload) {
    return axios.post(`/api/v2/wallet/rrc/exchange/order/sell`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletRRCExchangeOrderSellCancelPut(payload) {
    return axios.put(`/api/v2/wallet/rrc/exchange/order/sell/cancel`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },


  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletRRCExchangeOrderBuyMineGet(payload) {
    return axios.get(`/api/v2/wallet/rrc/exchange/order/buy/mine`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletRRCExchangeOrderBuyGet(payload) {
    return axios.get(`/api/v2/wallet/rrc/exchange/order/buy`, {
      params: payload.params,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },

  /**
   * 
   * @param {*} payload 
   * @returns 
   */
  walletRRCExchangeOrderBuyPost(payload) {
    return axios.post(`/api/v2/wallet/rrc/exchange/order/buy`, payload.data, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${store.getters.token.accessToken}`,
      },
    });
  },
};
